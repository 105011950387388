import {
  ContactCTA,
  HomeHero,
  KitchenExploreCTA,
  KitchenVisualizerCTA,
  NewsWidget,
  PartnerTicker,
  QuickLinks,
  Stats,
  Trustpilot,
} from "@prmf/site-components";
import { routes } from "@prmf/site-router";
import { Link, useLoaderData } from "@remix-run/react";

const dict = {
  private: {
    intro:
      "We have over 70 years experience of supplying contract kitchens to the UK’s leading private property developers. Our service levels and customer care are unrivalled in the industry, making Paula Rosa Manhattan the perfect partner for your kitchen project.",
    link: routes.privateDevelopers,
  },
  social: {
    intro:
      "Paula Rosa Manhattan is committed to manufacturing and supplying contract kitchens for the social housing sector which includes affordable housing kitchens and supported living kitchens. We exceed expectations of registered providers and residents alike and help you to gain credits in BREEAM and the Code for Sustainable Homes’ schemes.",
    link: routes.socialHousing,
  },
  bespoke: {
    intro:
      "We have over 70 years experience of supplying contract kitchens to the UK’s leading private property developers. Our service levels and customer care are unrivalled in the industry, making Paula Rosa Manhattan the perfect partner for your kitchen project.",
    link: routes.bespokeSolutions,
  },
};

const Intro: React.FC = () => {
  const { customerType } = useLoaderData<{ customerType: keyof typeof dict }>();
  const f = dict[customerType];
  return (
    <section className="container max-w-3xl space-y-8 pt-10 text-center md:pb-10">
      <h2 className="text-2xl font-bold md:text-3xl">
        A partner you can rely on
      </h2>
      <p className="font-light">{f.intro}</p>
      <Link
        className="mx-auto table rounded-full bg-neutral-400 px-4 py-2 text-sm text-white"
        to={f.link}
      >
        Find out more about PRMF
      </Link>
      <Trustpilot className="border md:hidden" />
    </section>
  );
};

const Home: React.FC = () => {
  return (
    <div>
      <HomeHero />
      <PartnerTicker />
      <Intro />
      <KitchenExploreCTA />
      <QuickLinks />
      <KitchenVisualizerCTA />
      <Stats
        data={[
          {
            label: "Kitchens manufactured",
            value: "278,976",
            since: "2015",
            linkLabel: "World class manufacturing",
            linkRoute: routes.innovation,
            bgColor: "#FFCC80",
          },
          {
            label: "Green energy used",
            value: "12.7",
            unit: "Gwh",
            since: "2018",
            linkLabel: "Our sustainability journey",
            linkRoute: routes.sustainability,
            bgColor: "#6AE8A4",
          },
          {
            label: "CO2 avoided",
            value: "2611",
            unit: "tonnes",
            since: "2018",
            linkLabel: "Our sustainability journey",
            linkRoute: routes.sustainability,
            bgColor: "#6AE8A4",
          },
          {
            label: "Years in business",
            value: "77",
            since: "1946",
            linkLabel: "Our History",
            linkRoute: routes.history,
            bgColor: "#80EFFF",
          },
        ]}
      />
      <NewsWidget />
      <ContactCTA />
    </div>
  );
};

export default Home;
