import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import BgImage from "./BgImage";
import InView from "./InView";
import SmartLink from "./SmartLink";

const KitchenVisualizerCTA: React.FC = () => {
  const Dot: React.FC<{ title: string; className?: string }> = (props) => {
    return (
      <div
        className={clsx(
          "select-none",
          "hidden lg:flex",
          "group items-center gap-2",
          "absolute z-10",
          "text-sm font-bold text-white",
          "transition duration-500 ease-in-out",
          props.className,
        )}
      >
        <PlusCircleIcon
          className="h-10 w-10 transition group-hover:scale-125"
          strokeWidth={1}
        />
        {props.title}
      </div>
    );
  };
  return (
    <InView.Self
      threshold={0.5}
      as={BgImage}
      src="/media/assets/kitchen-visualizer-cta.webp"
      className={clsx(
        "relative md:overflow-hidden",
        "flex aspect-square max-h-[70vh] items-end",
        "bg-gray-400 max-md:text-center md:py-10",
      )}
      //triggerOnce
    >
      {({ inView }) => (
        <>
          <Dot
            title="Worktops"
            className={clsx(
              "left-[40%] top-[55%] delay-100",
              !inView && "translate-y-10 opacity-0",
            )}
          />
          <Dot
            title="Doors"
            className={clsx(
              "left-[57%] top-[80%] delay-200 xl:top-[85%]",
              !inView && "translate-y-10 opacity-0",
            )}
          />
          <Dot
            title="Handles"
            className={clsx(
              "left-[75%] top-[30%] delay-300 xl:left-[76%]",
              !inView && "-translate-y-10 opacity-0",
            )}
          />
          <div className="container">
            <div
              className={clsx(
                "max-w-xs overflow-hidden rounded-lg bg-white shadow-lg",
                "transition duration-300 ease-in-out",
                "max-md:relative max-md:top-20 max-md:mx-auto",
                !inView && "translate-y-40 opacity-0",
              )}
            >
              <h2 className="bg-accent p-4 font-bold">
                Bring your inspiration to life
              </h2>
              <p className="p-4 text-xs leading-relaxed">
                Get creative and visualise your PRMF kitchen. Explore door,
                handle and worktop options from our current range then save your
                designs, send them to a colleague or print off the details to
                use in your next project.
              </p>
              <SmartLink
                className={clsx(
                  "mx-4 table p-2 px-4",
                  "text-xs font-bold text-white",
                  "rounded-t-lg bg-black",
                  "hover:bg-accent hover:text-black",
                  "max-md:mx-auto",
                )}
                to="https://visualiser.prmf.co.uk"
              >
                Design your own
              </SmartLink>
            </div>
          </div>
        </>
      )}
    </InView.Self>
  );
};

export default KitchenVisualizerCTA;
